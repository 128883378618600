<template>
  <div class="snackbar">
    <v-snackbar
          v-model="snackbarOpen"
          :color="$isUndefined(snackbar.color) ? 'black' : snackbar.color"
          top
          rounded="pill"
        >
          {{ snackbar.text || 'SomeMessage'}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbar.btnColor"
              text
              v-bind="attrs"
              @click="closeSnackBar"
            >
              {{ snackbar.btnText || 'Close'}}
            </v-btn>
          </template>
          <v-progress-linear indeterminate v-if="showLoading"></v-progress-linear>
        </v-snackbar>
  </div>
</template>

<script>


export default {
  name: 'snackbar',
  props:['snackbar'],
  computed:{
    showLoading(){
      return !this.$isUndefined(this.snackbar.loading);
    }
  },
  data: () => ({
    snackbarOpen:true,
  }), //end data
  methods:{
    closeSnackBar() {
      this.$emit('close-snackbar', true);
    },
  },
};
</script>


<style lang="scss">
.choose-time{
  .date-container{
    border-radius: 6px;
    padding-bottom: -1px;
  }
  .dates{
    cursor:pointer;
    .date{
      // font-family: 'Nexa Text Book';

      .month{
        color: #aaa;
        font-size: 12px;
        line-height: 12px;
      }
      .day{
        font-size: 22px;
        line-height: 28px;
        color: #555;
      }
    }
    .top-line{
      font-size: 15px;
      line-height: 18px;
      color: #444;
    }
    .bottom-line{
      font-size: 13px;
      line-height: 20px;
    }
    svg{
      width: 24px;
    }
  }
  .vc-container{
    .is-today{
      .vc-highlights .vc-highlight{border-color: var(--v-secondary-base)!important}
      .vc-day-content{color: var(--v-secondary-base)}
    }
  }
}
</style>
